<template>
  <div>
    <div
      @click="downloadAllQr"
      class="button is-primary is-light is-small"
      :class="{ 'is-loading': loading }"
    >
      <i class="fas fa-qrcode mr-2"></i>
      Download all team QR codes
    </div>
    <!-- Hidden QR codes -->
    <div v-show="false">
      <vue-qrcode
        v-for="team in session.teams"
        :key="team.teamCode"
        :id="`team-qr-code-${team.teamCode}`"
        :value="`https://pyts.link/lobby/${team.teamCode}`"
      />
    </div>
  </div>
</template>

<script>
import VueQrcode from 'vue-qrcode'

export default {
  name: 'DownloadAllTeamQrButton',
  components: {
    VueQrcode
  },
  props: {
    session: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    async downloadAllQr () {
      if (!this.session.teams || this.session.teams.length <= 1) {
        this.$buefy.toast.open({
          message: 'Need more than one team to use bulk download',
          type: 'is-warning',
          queue: false
        })
        return
      }

      this.loading = true
      try {
        for (const team of this.session.teams) {
          const el = document.querySelector(`#team-qr-code-${team.teamCode}`)
          const downloadLink = document.createElement('a')
          downloadLink.href = el.src
          downloadLink.download = `${team.name} - ${team.teamCode}.png`
          downloadLink.click()
        }

        this.$buefy.toast.open({
          message: 'Successfully downloaded all QR codes',
          type: 'is-primary',
          queue: false
        })
      } catch (err) {
        this.$buefy.toast.open({
          message: err.message,
          type: 'is-danger',
          queue: false
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
