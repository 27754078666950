<template>
  <div v-if="session">
    <hero-bar>
      <div class="level" style="width: 100%;" v-if="adventure">
        <div class="level-left">
          <div class="level-item">
            <router-link
            :to="{name:'managePlayTour', params: {adventureId: session.adventureId}}"
            class="button is-primary is-light mr-2">
              <i class="fas fa-chevron-left mr-2"></i>
              Manage game
            </router-link>
          </div>
          <div class="level-item">
            <div>
              <div class="is-size-7">
                {{ adventure.details.adventure_details.name }}
              </div>
              <div>
                Session: {{ session.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <session-delete-button slot="right" :session="session"/>
      <session-duplicate-button slot="right" :session="session"/>
      <session-reset-button slot="right" :session="session"/>
    </hero-bar>
    <div class="tabs is-justify-content-center mb-5">
      <ul>
        <li :class="{'is-active': !tabOpened}" @click="changeTab(null)"><a>Start Playing</a></li>
        <li :class="{'is-active': tabOpened === 'Configure'}" @click="changeTab('Configure')"><a>Configure</a></li>
        <li :class="{'is-active': tabOpened === 'Teams'}" @click="changeTab('Teams')"><a>Teams</a></li>
        <li :class="{'is-active': tabOpened === 'Leaderboard & Results'}" @click="changeTab('Leaderboard & Results')"><a>Leaderboard & Results</a></li>
        <li :class="{'is-active': tabOpened === 'Gallery'}" @click="changeTab('Gallery')" v-if="session.galleryPhotos"><a>Gallery</a></li>
        <li :class="{'is-active': tabOpened === 'Completion Certificates'}" @click="changeTab('Completion Certificates')" v-if="session.hasCompletionCert"><a>Completion Certificates</a></li>
        <li :class="{'is-active': tabOpened === 'Access Codes'}" @click="changeTab('Access Codes')" v-if="session.accessCodes"><a>Access Codes</a></li>
        <li :class="{'is-active': tabOpened === 'Analytics'}" @click="changeTab('Analytics')"><a>Analytics</a></li>
      </ul>
    </div>

    <section v-if="!tabOpened" class="section is-main-section pt-0 pb-5">
      <session-tutorial :session="session" />
    </section>

    <section v-if="tabOpened === 'Configure'" class="section is-main-section pb-0 pt-0">
      <tiles>
        <edit-session-form :session="session" class="tile is-child" />
      </tiles>
    </section>

    <section v-if="tabOpened === 'Teams'" class="section is-main-section pt-0 pb-5">
      <tiles>
        <card-widget
          class="tile is-child"
          type="is-primary"
          icon="fa-running"
          :number="session.playersJoined"
          label="Players Joined"
        />
        <card-widget
          class="tile is-child"
          type="is-info"
          icon="fa-users"
          :number="session.teamsJoined"
          label="Teams Created"
        />
      </tiles>
    </section>

    <section v-if="tabOpened === 'Teams'" class="section pt-0">
      <add-pre-created-team :session="session" />
      <card-component
        v-if="session"
        class="has-table has-mobile-sort-spaced mb-1"
        title="Teams"
        icon="fa-users"
      >
        <teams :session="session" class="tile is-child" />
      </card-component>
      <div class="is-flex is-justify-content-space-between is-align-items-center">
        <div class="has-text-grey-light is-size-7">
          Team links and data are automatically deleted once they are 90 days old for privacy reasons.
        </div>
        <download-all-team-qr-button v-if="session.teams && session.teams.length > 1" :session="session" />
      </div>
    </section>

    <section v-if="tabOpened === 'Leaderboard & Results'" class="section pt-0">
      <div class="is-flex is-justify-content-space-between">
        <open-session-exhibit :session="session" :buttonText="'Open leaderboard exhibit'"/>
        <div class="is-flex">
          <download-results v-if="session" :session="session" class="mb-1 mr-1"/>
          <download-leaderboard v-if="session" :session="session" class="mb-1"/>
        </div>
      </div>
      <card-component
        v-if="session"
        class="has-table has-mobile-sort-spaced"
        title="Session Leaderboard"
        icon="fa-stream"
      >
        <leaderboard :session="session" class="tile is-child" />
      </card-component>
    </section>

    <section v-if="tabOpened === 'Gallery'" class="section pt-0">
      <div class="is-flex is-justify-content-space-between mb-1">
        <open-session-exhibit :session="session"/>
        <delete-session-media :session="session"/>
      </div>
      <card-component
        v-if="session"
        class="has-table has-mobile-sort-spaced"
        title="Gallery"
        icon="fa-photo-video"
      >
        <session-photo-gallery :galleryPhotos="session.galleryPhotos" :session="session" class="tile is-child" />
      </card-component>
    </section>

    <section v-if="tabOpened === 'Completion Certificates'" class="section pt-0">
      <completion-certs-tools :session="session" class="mb-1"/>
      <card-component
        v-if="session"
        class="has-table has-mobile-sort-spaced"
        title="Completion Certificates"
        icon="fa-certificate"
      >
        <completion-certificates :session="session" class="tile is-child" />
      </card-component>
    </section>

    <section v-if="tabOpened === 'Access Codes'" class="section pt-0">
      <access-codes-tools :session="session" class="mb-1"/>
      <card-component
        v-if="session"
        class="has-table has-mobile-sort-spaced"
        title="Access Codes"
        icon="fa-key"
      >
        <access-codes-table :accessCodes="session.accessCodes" class="tile is-child" />
      </card-component>
    </section>

    <section v-if="tabOpened === 'Analytics'" class="section pt-0">
      <card-component
        v-if="session"
        class="has-table has-mobile-sort-spaced"
        title="Session Analytics"
        icon="fa-chart-line"
      >
        <session-analytics :sessionId="session.id" class="tile is-child" />
      </card-component>
    </section>

  </div>
</template>

<script>
import firebaseApp from '@/firebase/init'
import EditSessionForm from '@/components/EditSessionForm'
import Leaderboard from '@/components/Leaderboard'
import Teams from '@/components/Teams'
import SessionAnalytics from '@/components/SessionAnalytics'
import HeroBar from '@/components/HeroBar'
import Tiles from '@/components/Tiles'
import CardWidget from '@/components/CardWidget'
import CardComponent from '@/components/CardComponent'
import AddPreCreatedTeam from '@/components/AddPreCreatedTeam'
import DownloadLeaderboard from '@/components/DownloadLeaderboard'
import DownloadResults from '@/components/DownloadResults'
import CompletionCertsTools from '@/components/CompletionCertsTools'
import CompletionCertificates from '@/components/CompletionCertificates'
import SessionPhotoGallery from './SessionPhotoGallery.vue'
import SessionTutorial from '@/components/SessionTutorial'
import DeleteSessionMedia from '@/components/DeleteSessionMedia'
import OpenSessionExhibit from '@/components/OpenSessionExhibit'
import SessionResetButton from '../components/SessionResetButton.vue'
import SessionDuplicateButton from '../components/SessionDuplicateButton.vue'
import SessionDeleteButton from '../components/SessionDeleteButton.vue'
import AccessCodesTable from '../components/AccessCodesTable.vue'
import AccessCodesTools from '../components/AccessCodesTools.vue'
import DownloadAllTeamQrButton from '../components/DownloadAllTeamQrButton.vue'

export default {
  name: 'SessionEdit',
  components: {
    HeroBar,
    EditSessionForm,
    Tiles,
    CardWidget,
    CardComponent,
    Leaderboard,
    Teams,
    SessionAnalytics,
    AddPreCreatedTeam,
    DownloadLeaderboard,
    DownloadResults,
    CompletionCertsTools,
    CompletionCertificates,
    SessionPhotoGallery,
    SessionTutorial,
    DeleteSessionMedia,
    OpenSessionExhibit,
    SessionResetButton,
    SessionDuplicateButton,
    SessionDeleteButton,
    AccessCodesTable,
    AccessCodesTools,
    DownloadAllTeamQrButton
  },
  data () {
    return {
      sessionId: this.$route.params.sessionId,
      session: null,
      tabOpened: null
    }
  },
  methods: {
    changeTab (name) {
      this.tabOpened = name
    }
  },
  watch: {
    sessionId: {
      immediate: true,
      handler (sessionId) {
        const sessions = firebaseApp.firestore().collection('sessions')
        if (sessionId) {
          this.$bind('session', sessions.doc(sessionId))
        }
      }
    }
  },
  mounted () {
    this.$mixpanel.track('page_visit', {
      distinct_id: this.$store.state.profile.organisationId,
      organisation_name: this.$store.state.organisation.name,
      user_email: this.$store.state.profile.email,
      page_name: this.$route.name
    })
  },
  computed: {
    adventure () {
      return this.$store.state.adventures.filter(el => {
        return el.id === this.session.adventureId
      })[0]
    },
    organisation () {
      return this.$store.state.organisation
    }
  }
}
</script>
